// import React, { useEffect, useState } from 'react';
// import { useInView } from 'react-intersection-observer';
// import { Vamshi, Jackson, Vijay, Sivan, Vyshak, omkar, artpark, iisc, mhi,dst,gok,miind} from '../../assets/img/imports';
// import './About.css';

// export const About = () => {
//     const [ref, inView] = useInView({
//         triggerOnce: true,
//         threshold: 0.1,
//     });
//     const [isLoaded, setIsLoaded] = useState(false);

//     useEffect(() => {
//         if (inView) {
//             const timer = setTimeout(() => {
//                 setIsLoaded(true);
//             }, 500); // Delay to create a slow loading effect
//             return () => clearTimeout(timer);
//         }
//     }, [inView]);

//     return (
//         <section id="about" ref={ref}>
//             <div className={`about_section ${isLoaded ? 'loaded' : ''}`}>
//                 <div className="about_heading">
//                     <h2>About Us</h2>
//                     <p>Advanced System-on-Modules integrating AI-enhanced
//                         GNSS and INS capabilities. Empowering buyers
//                         with smarter, cost-effective solutions.</p>

//                     <div className="meet_section">
//                         <h3>Meet the team</h3>

//                         <div className="info">
//                         <div className="individual_info">
//                                 <img src={Vijay} alt="Vijay" />
//                                 <p><b>Vijay H P</b><br /> Chief of Engineering & Administration</p>
//                             </div>
//                             <div className="individual_info">
//                                 <img src={Vamshi} alt="Vamshi" />
//                                 <p><b>Vamshi Bharadwaj M V</b><br />Chief of Technology and Execution</p>
//                             </div>
//                             <div className="individual_info">
//                                 <img src={Vyshak} alt="Vyshak" />
//                                 <p><b>Vyshak Y</b><br /> Chief of Operations & Business</p>
//                             </div>

//                             <div className="individual_info">
//                                 <img src={Sivan} alt="Sivan" />
//                                 <p><b>Sivan Sriman</b><br /> Cheif Embedded Engineer</p>
//                             </div>
//                             <div className="individual_info">
//                                 <img src={Jackson} alt="Jackson" />
//                                 <p><b>Jackson Charles</b><br /> Chief Animator & Head of Product Management</p>
//                             </div>

//                         </div>
//                     </div>

//                     <div className="meet_section">
//                         <h3>Our Advisor</h3>
//                         <div className="info">
//                             <div className="individual_info">
//                                 <img src={omkar} alt="Omkar" />
//                                 <p><b>Dr. S.N. Omkar</b><br /> Chief Research Scientist (CRS) at the Department of Aerospace Engineering, Indian Institute of Science, Bengaluru</p>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="meet_section">
//                         <h3>Our Support Group</h3>
//                         <div className="info">
//                             <div className="individual_info">
//                                 <img src={artpark} alt="ARTPARK" />
//                             </div>
//                             <div className="individual_info_2">
//                                 <img src={iisc} alt="IISc" />
//                             </div>
//                             <div className="individual_info_2">
//                                 <img src={gok} alt="gok" />
//                             </div>
//                             <div className="individual_info">
//                                 <img src={mhi} alt="MHI" />
//                             </div>
//                             <div className="individual_info">
//                                 <img src={dst} alt="dst" />
//                             </div>

//                         </div>
//                     </div>
//                     <div className="meet_section">
//                         <div className="info">
//                             <div className="individual_info">
//                                 <img src={miind} alt="dst" />
//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </section>
//     );
// };
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Vamshi, Jackson, Vijay, Sivan, Vyshak, omkar, artpark, iisc, mhi, dst, gok, miind } from '../../assets/img/imports';
import './About.css';

export const About = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (inView) {
            const timer = setTimeout(() => {
                setIsLoaded(true);
            }, 500); // Delay to create a slow loading effect
            return () => clearTimeout(timer);
        }
    }, [inView]);

    return (
        <section id="about" ref={ref}>
            <div className={`about_section ${isLoaded ? 'loaded' : ''}`}>
                <div className="about_heading">
                    <h2>About Us</h2>
                    <p>Advanced System-on-Modules integrating AI-enhanced
                        GNSS and INS capabilities. Empowering buyers
                        with smarter, cost-effective solutions.</p>

                    <div className="meet_section">
                        <h3>Meet the team</h3>
                        <div className="info">
                            <div className="individual_info">
                                <img src={Vijay} alt="Vijay" />
                                <p><b>Vijay H P</b><br /> Chief of Engineering & Administration</p>
                            </div>
                            <div className="individual_info">
                                <img src={Vamshi} alt="Vamshi" />
                                <p><b>Vamshi Bharadwaj M V</b><br />Chief of Technology and Execution</p>
                            </div>
                            <div className="individual_info">
                                <img src={Vyshak} alt="Vyshak" />
                                <p><b>Vyshak Y</b><br /> Chief of Operations & Business</p>
                            </div>
                            <div className="individual_info">
                                <img src={Sivan} alt="Sivan" />
                                <p><b>Sivan Sriman</b><br /> Cheif Embedded Engineer</p>
                            </div>
                            <div className="individual_info">
                                <img src={Jackson} alt="Jackson" />
                                <p><b>Jackson Charles</b><br /> Chief Animator & Head of Product Management</p>
                            </div>
                        </div>
                    </div>

                    <div className="meet_section">
                        <h3>Our Advisor</h3>
                        <div className="info">
                            <div className="individual_info">
                                <img src={omkar} alt="Omkar" />
                                <p><b>Dr. S.N. Omkar</b><br /> Chief Research Scientist (CRS) at the Department of Aerospace Engineering, Indian Institute of Science, Bengaluru</p>
                            </div>
                        </div>
                    </div>

                    <div className="meet_section">
                        <h3>Our Support Group</h3>
                        <div className="info">
                            <div className="individual_info">
                                <img src={artpark} alt="ARTPARK" />
                            </div>
                            <div className="individual_info_2">
                                <img src={iisc} alt="IISc" />
                            </div>
                            <div className="individual_info_2">
                                <img src={gok} alt="gok" />
                            </div>
                            <div className="individual_info">
                                <img src={mhi} alt="MHI" />
                            </div>
                            <div className="individual_info">
                                <img src={dst} alt="dst" />
                            </div>
                        </div>
                    </div>
                    <div className="meet_section">
                        <div className="info">
                            <div className="individual_info">
                                <img src={miind} alt="miind" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
