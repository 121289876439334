import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './Services.css';

const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const [isOpenSensorYqxModules, setIsOpenSensorYqxModules] = useState(false);
  const [isOpenSensorYdxModules, setIsOpenSensorYdxModules] = useState(false);
  const [isOpenAdditionalFeatures, setIsOpenAdditionalFeatures] = useState(false);
  const [isOpenOtherProducts, setIsOpenOtherProducts] = useState(false);

  useEffect(() => {
    if (inView) {
      const timer = setTimeout(() => {
        setIsLoaded(true);
      }, 500); // Delay to create a slow loading effect
      return () => clearTimeout(timer);
    }
  }, [inView]);

  return (
    <section id="services" ref={ref}>
      <div className={`services_section ${isLoaded ? 'loaded' : ''}`}>
        <div className="services_heading">
          <h2>Our Services</h2>
        </div>
        <div className="services_info">
          <div className="services_individual_info left-aligned" >
            <h3>
              Sensor Modules
            </h3>
            
              <h4 className="left-aligned"  onClick={() => setIsOpenSensorYqxModules(!isOpenSensorYqxModules)}>
                Y'Quarternionix 
                {isOpenSensorYqxModules && (
                  <h5 className="left-aligned"  >
                   - Y.Q.X.001 <br /> <br />
                   - Y.Q.X.SOM 
                  </h5>
                )}
                
              </h4>
              <h4 className="left-aligned"  onClick={() => setIsOpenSensorYdxModules(!isOpenSensorYdxModules)}>
                Y'Dynamix 
                {isOpenSensorYdxModules && (
                  <h5 className="left-aligned"  >
                   - Y.D.X.001 <br /> <br />
                   - Y.D.X.SOM 
                  </h5>
                )}
                
              </h4>
          </div>
          <div className="services_individual_info">
            <h3 onClick={() => setIsOpenAdditionalFeatures(!isOpenAdditionalFeatures)}>
              Additional Features
            </h3>
            {isOpenAdditionalFeatures && (
              <p className="left-aligned">
                Firmware Support <br /> <br />
                Aftermarket Service <br /> <br />
                Assisted Updates <br /> <br />
                Calibration software support <br /> <br />
              </p>
            )}
          </div>
          <div className="services_individual_info">
            <h3 onClick={() => setIsOpenOtherProducts(!isOpenOtherProducts)}>
              Other Products
            </h3>
            {isOpenOtherProducts && (
              <p className="left-aligned">
                Breakout boards <br /> <br />
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
